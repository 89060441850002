.default-placeholder {
  border-radius: 10px !important;
  min-width: 10px !important;
  overflow: hidden;
}

.date-picker-app-wrapper {
  width: 100% !important;
}

.dengor {
  background-color: #ffdddd !important;
}
.full-height {
  height: 100svh !important;
}

.plceholdercal {
  position: relative;
}

.iconcal {
  position: absolute;
  right: 13px;
  top: 35%;
}
